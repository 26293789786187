import React from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles, AccordionActions, Button, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    left: {
        flex: '1 1 auto',
        marginRight: theme.spacing(2)
    },
    right: {
        flex: '1 1 0',
        textAlign: 'right',
    },
    vol: {
        color: theme.palette.primary.main
    }
}))

const AgendaItem = (props) => {
    const { handleOpen, handleChange, expanded, titel, beschrijving, vol, plekken, datum, startTijd, eindTijd, locatie} = props;
    const classes = useStyles();

    return (
        <Accordion 
            expanded={expanded}
            onChange={handleChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                
            >
                <div className={classes.row}>
                    <div className={`${classes.column} ${classes.left}`}>
                        <Typography>
                            {titel}
                        </Typography>
                        <Typography variant="caption">
                            {`${datum} ${startTijd}-${eindTijd}`}
                        </Typography>
                    </div>
                    <div className={classes.right}>
                        { vol 
                            ? <Typography className={classes.vol}>VOL</Typography>
                            : <Typography>{`${plekken} Plekken Beschikbaar`}</Typography>
                        }
                    </div>
                </div>
            </AccordionSummary>
            
            <AccordionDetails>
                <Typography>
                    {beschrijving}
                    {beschrijving !== '' && <><br/><br/></>}
                    <b>Locatie</b>
                    <br/>{locatie}
                </Typography>
            </AccordionDetails>
            <Divider/>
            {!vol &&
                <AccordionActions>
                    <Button size="small" color="primary" onClick={handleOpen}>Offerte Aanvragen</Button>
                </AccordionActions>
            }
        </Accordion>
    )
}

export default AgendaItem