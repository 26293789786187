import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './routes/Home';
import Brandbeveiliging from './routes/Brandbeveiliging';
import Cursussen from './routes/Cursussen';
import Producten from './routes/Producten';
import Layout from './components/Layout';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#CF1821'
    },
    secondary: {
      main: '#24F94A',  
    },
    background: {
      default: '#FFF'
    }
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/brandbeveiliging">
              <Brandbeveiliging/>
            </Route>
            <Route path="/cursussen">
              <Cursussen/>
            </Route>
            <Route path="/producten">
              <Producten/>
            </Route>
          </Switch>
        </Layout>
      </ThemeProvider>
    </Router>
  );
}

export default App;
