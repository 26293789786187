import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import IconBox from '../components/IconBox'
import { Typography, makeStyles, Container, Grid, Paper, Hidden, Button} from '@material-ui/core';
import HeroSlideshow from '../components/HeroSlideshow'
import brandblussers from '../images/header/brandblussers.jpg'
import aed from '../images/header/aed.jpg'
import poppen from '../images/header/poppen.jpg'
import gebouw from '../images/header/gebouw.jpg'
import oefenruimte from '../images/header/oefenruimte.jpg'
import keurmerk from '../images/keurmerken.png'
import verbandkoffer from '../images/bhv_koffer.png'
import henk from '../images/henk.png'
import aukje from '../images/aukje2.png'
import { useLocation } from 'react-router-dom';
import Gallery from '../components/Gallery';
import IconDivider from '../components/IconDivider'
import Href from '@material-ui/core/Link'
import one from '../images/grid/1.jpg'
import two from '../images/grid/2.jpg'
import three from '../images/grid/3.jpg'
import four from '../images/grid/4.jpg'
import five from '../images/grid/5.jpg'
import six from '../images/grid/6.jpg'
import seven from '../images/grid/7.jpg'
import eight from '../images/grid/8.jpg'
import nine from '../images/grid/9.jpg'
import ten from '../images/grid/10.jpg'
import eleven from '../images/grid/11.jpg'
import twelve from '../images/grid/12.jpg'
import BookmarkIcon from '@material-ui/icons/BookmarkBorderOutlined'
import BuildIcon from '@material-ui/icons/Build'
import SecurityIcon from '@material-ui/icons/Security'
import ShippingIcon from '@material-ui/icons/LocalShipping'

const images = [
  {
    src: one,
    alt: '',
  },
  {
    src: two,
    alt: '',
  },
  {
    src: three,
    alt: '',
  },
  {
    src: four,
    alt: '',
  },
  {
    src: five,
    alt: '',
  },
  {
    src: six,
    alt: '',
  },
  {
    src: seven,
    alt: '',
  },
  {
    src: eight,
    alt: '',
  },
  {
    src: nine,
    alt: '',
  },
  {
    src: ten,
    alt: '',
  },
  {
    src: eleven,
    alt: '',
  },
  {
    src: twelve,
    alt: '',
  },
]

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    overflowX: 'hidden',
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[100]
  },
  img: {
    maxWidth: '100%',
    maxHeight: 150
  },
  teamPaper: {
    padding: theme.spacing(8, 3, 3, 3),
    position: 'relative',
  },
  avatar: {
    position: 'absolute',
    left: 'calc(50% - 50px)',
    top: -50,
    width: 100,
    height: 100,
  },
  maps: {
    border: 0,
    width: '100%',
    height: 330,
  },
  footer: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F9FA',
    overflowX: 'hidden',
  },
  veiligheid: {
    [theme.breakpoints.only('sm')]: {
      order: 3
    },
  },
  voorraad: {
    [theme.breakpoints.only('sm')]: {
      order: 2
    },
  },
  quote: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  bold: {
    fontStyle: 'italic',
    color: theme.palette.primary.main
  }
}))

const Home = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const contactRef = useRef(null);

  useEffect(() => {
    if (location.hash === "#contact") {
      window.scrollTo(0, contactRef.current.offsetTop)
    } else {
      window.scrollTo(0, 0)
    }
  })

  return (
    <>
      <HeroSlideshow
        title="Ongeluk zit in een klein hoekje"
        content="Laat uw pand tegen brand beveiligen, voorzie uw werknemers of uzelf van de vaardigheden om mensenlevens te redden en de certificering om problemen met de overheid, uw verzekering of uw huurbaas te voorkomen na een ongeval."
        slides={[aed, gebouw, oefenruimte, poppen, brandblussers]}
      >
        <div>
          <Button color="primary" variant="contained" style={{marginRight: 16}} component={Link} to="/brandbeveiliging">Brandbeveiliging</Button>
          <Button color="primary" variant="outlined" component={Link} to="/cursussen">Cursussen</Button>
        </div>
      </HeroSlideshow>
      <Container component="section" maxWidth="md" className={classes.section}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Zoekt u een gecertificeerd bedrijf voor al uw veiligheids&shy;zaken?
        </Typography>
        <Typography variant="body1" align="center">
          Groot in BHV en Brandbeveiliging is voor u enthousiast actief op de Nederlandse markt. U wilt prijs, kwaliteit en gedegen service die u aan alle eisen van de overheid en verzekering laat voldoen. U wilt een aanspreekpunt en een persoon die alles voor u regelt en daarmee niet alleen kosten, maar vooral ook uw kostbare tijd bespaart.
        </Typography>
      </Container>
      <section className={classes.greyBackground}>
        <Container maxWidth="md" className={classes.section}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <IconBox
                icon={<BuildIcon/>}
                title="Vakmanschap"
                content="Wij zijn KIWA-REOB erkend. Dit is een garantie voor kwaliteit en deskundigheid."
              >
                <img src={keurmerk} alt="KIWA-REOB keurmerk" className={classes.img}/>
              </IconBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.veiligheid}>
              <IconBox
                icon={<SecurityIcon/>}
                title="Veiligheid en Gemak"
                content="Wij houden de controledata voor u in de gaten en verzorgen de jaarlijkse controles volgens NEN normen op het bedrijf of bij u thuis. Wij kunnen voorzien in de levering, het onderhoud en de controle van brand&shy;blus&shy;toestellen, brand&shy;haspels, nood&shy;verlichting en AED. Ook voorzien wij in BHV, VCA, EHBO, Kinder-EHBO en AED & Reanimatie cursussen."
              >
              </IconBox>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.voorraad}>
              <IconBox
                icon={<ShippingIcon/>}
                title="Voorraad"
                content="Wij kunnen verscheidene kleine blus&shy;middelen, verband&shy;koffers en andere veiligheids&shy;artikelen uit voorraad leveren."
              >
                <Hidden mdUp>
                  <img src={verbandkoffer} alt="verbandkoffer" className={classes.img}/>
                </Hidden>
              </IconBox>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Container component="section" maxWidth="md" className={classes.section}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" style={{marginBottom: 32}}>
              Het Team
            </Typography>
            <IconDivider icon={<BookmarkIcon/>}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.teamPaper}>
              <img src={henk} alt="henk de groot" className={classes.avatar}/>
              <Typography variant="h4" component="h3" align="left">
                Henk de Groot
              </Typography>
              <Typography variant="overline" align="left" gutterBottom>
                Brandbeveiliging & Instructeur
              </Typography>
              <Typography variant="body2" align="left" gutterBottom>
                <br/><b className={classes.bold}>Ik ben begonnen als beveiligingsbeabmbte</b> en werkte veel nachten en weekenden. Een vriend van mij begon destijds huis aan huis rookmelders te verkopen en is toen een bedrijf begonnen dat huis aan huis inbraaksystemen aanbood op lease basis en ik heb welgeteld 6 maanden voor zijn bedrijf gewerkt, tot deze failliet ging. 
                <br/>
                <br/>Na een tijdje thuis te hebben gezeten kwam mijn vrouw, Aukje, aan met een advertentie in de krant voor een baan als <b className={classes.bold}>onderhoudsmonteur bij Nu Swift Brandbeveiliging.</b> In eerste instantie was ik niet geïntresseerd en ben ik het huis gaan verven, waarna Aukje zich heeft uitgegeven voor mij en voor mij heeft gesolliciteerd. 
                <br/>
                <br/>Toen ik werd uitgenodigd voor een sollicitatiegesprek, ben ik toch wel gegaan en werd ik als enige van de 120 sollicitanten aangenomen! 
                <br/>
                <br/><div className={classes.quote}>"Als commericieel onderhoudsmonteur en vertegenwoordiger heb ik in negen jaar veel geleerd over brandbeveiliging."</div>
                <br/>In 2006 ben ik begonnen met de <b className={classes.bold}>opleiding tot kader instructeur EHBO van het Oranje Kruis en de Nederlandse Reanimatie Raad.</b> Na twee jaar heb ik mijn diploma gehaald en mocht ik Reanimatie en AED, BHV, Kinder-EHBO en VCA cursussen geven.
                <br/>
                <br/>Ik wilde graag  een BHV bedrijf op zetten, maar dit was niet mogelijk in combinatie met mijn huidige baan. Ik ben toen met wederzijds goedvinden vertrokken bij Nu Swift Brandbeveiliging en <b className={classes.bold}>augustus 2008 stond Groot in BHV & Brandbeveiliging officieel ingeschreven bij de KvK.</b>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.teamPaper}>
              <img src={aukje} alt="aukje de groot" className={classes.avatar}/>
              <Typography variant="h4" component="h3" align="left">
                Aukje de Groot
              </Typography>
              <Typography variant="overline" align="left" gutterBottom>
                Administratie & Instructeur
              </Typography>
              <Typography variant="body2" align="left" gutterBottom>
                <br/>Mijn eerste vaste baan was als kapper en heb 15 jaar met veel plezier in Dokkum gewerkt. Wegens gezondheidsredenen heb ik toen een carrière switch gemaakt en <b className={classes.bold}>een opleiding tot secretaresse gevolgd.</b> Daarna heb ik enkele jaren gewerkt voor verscheidene werkgevers en uiteindelijke langere tijd vast voor de Friesland bank als client adviseur, tot deze werd gesloten.
                <br/>
                <br/>In de tijd dat Henk het bedrijf opzette, hield ik me niet actief bezig met Groot in BHV & Brandbeveiliging. Er werd wel eens naar ons huis gebeld door klanten en die telefoontjes nam ik vaak op, maar 
                <br/>
                <br/><div className={classes.quote}>"ik wist eerlijk gezegd het verschil niet tussen een pleister en een snelverband."</div>
                <br/>Om mijn eigen kennis een beetje bij te spijkeren besloot ik om een cursus EHBO te volgen bij het Oranje Kruis in Burgum. Nadat ik het diploma had behaald, werd ik aangemoedigd door mijn examinator om net zoals Henk een <b className={classes.bold}>opleiding tot kaderinstructeur Oranje Kruis</b> te volgen. In eerste instantie had ik zoiets van, daar heb ik toch helemaal geen zin in. Dat ga ik niet doen!
                <br/>
                <br/><b className={classes.bold}>Uiteindelijk raakte ik zo betrokken bij het bedrijf</b> dat ik, naast administratief werk, Henk ook wilde ondersteunen bij de lessen. Ik heb toen ook het diploma voor kader instructeur EHBO Oranje Kruis gehaald en daarna zelfs nog de de LOTUS opleiding (simulatie slachtoffer) gedaan.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <section>
        <Gallery
          images={images}
        />
      </section>
      <section ref={contactRef} style={{lineHeight: 0}}>
        <Container maxWidth="md" className={classes.section}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2" align="center" style={{marginBottom: 32}}>
                Contact
              </Typography>
              <IconDivider icon={<BookmarkIcon/>}/>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} sm={8}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1483.9769823691713!2d5.981711539550547!3d53.20116113798176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a16e1feab1c9c08!2sVOF%20Groot%20in%20BHV%20%26%20Brandbeveiliging!5e0!3m2!1sen!2snl!4v1595516128133!5m2!1sen!2snl" 
                  title="google maps"
                  frameBorder="0" 
                  allowFullScreen="" 
                  aria-hidden="false" 
                  tabIndex="0"
                  className={classes.maps}
                ></iframe>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" align="left">
                  <b>Bezoekersadres
                  <br/>(Op Afspraak)</b>
                  <br/>Hannelswei 19F
                  <br/>9251 MR, Burgum
                  <br/>
                  <br/><b>Correspondentie&shy;adres</b>
                  <br/>Van der Walstrjitte 28
                  <br/>9251 RH, Burgum
                  <br/>
                  <br/><b>Contactgegevens</b>
                  <br/><Href href="tel:+31627081802"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>+31 6 27081802</Href>
                  <br/><Href href="tel:+31 6 21124200"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>+31 6 21124200</Href>
                  <br/><Href href="tel:0511463177"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>0511-463177</Href>
                  <br/><Href href="mailto:info@grootinbhv.nl"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>mail</i>info@grootinbhv.nl</Href>
                </Typography>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  <b>Bezoekersadres
                  <br/>(Op Afspraak)</b>
                  <br/>Hannelswei 19F
                  <br/>9251 MR, Burgum
                  <br/>
                  <br/><b>Correspondentie&shy;adres</b>
                  <br/>Van der Walstrjitte 28
                  <br/>9251 RH, Burgum
                  <br/>
                  <br/><b>Contactgegevens</b>
                  <br/><Href href="tel:+31627081802"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>+31 6 27081802</Href>
                  <br/><Href href="tel:+31 6 21124200"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>+31 6 21124200</Href>
                  <br/><Href href="tel:0511463177"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>phone</i>0511-463177</Href>
                  <br/><Href href="mailto:info@grootinbhv.nl"><i className="material-icons" style={{fontSize: 16, marginRight: 8}}>mail</i>info@grootinbhv.nl</Href>
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
        <Hidden smUp>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1483.9769823691713!2d5.981711539550547!3d53.20116113798176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a16e1feab1c9c08!2sVOF%20Groot%20in%20BHV%20%26%20Brandbeveiliging!5e0!3m2!1sen!2snl!4v1595516128133!5m2!1sen!2snl" 
            title="google maps"
            frameBorder="0" 
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="0"
            className={classes.maps}
          >
          </iframe>
        </Hidden>
      </section>
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1">
                Copyright &copy; {new Date().getFullYear()} V.O.F. Groot in BHV & Brandbeveiliging.
              </Typography>
            </Grid>
          </Grid>
          
        </Container>
      </footer>
    </>
  );
}

export default Home;
