import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Button } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import brandhaspel from '../images/pro-line brandslanghaspel.png'
import brandblussers from '../images/header/brandblussers.jpg'
import HeroSlideshow from '../components/HeroSlideshow'
import IconBox from '../components/IconBox'
import BuildIcon from '@material-ui/icons/Build'
import FlameIcon from '@material-ui/icons/Whatshot'
import PolicyIcon from '@material-ui/icons/Policy'

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    overflowX: 'hidden'
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[100]
  },
  brandhaspel: {
    display: 'block',
    marginLeft: 'auto',
    paddingRight: theme.spacing(3)
  },
  media: {
    height: 200
  },
  content: {

  },
  onderhoud: {
    [theme.breakpoints.only('sm')]: {
      order: 3
    },
  },
  specialistenwerk: {
    [theme.breakpoints.only('sm')]: {
      order: 2
    },
  },
}));

function Brandbeveiliging() {
  const classes = useStyles();

  return (
    <>
      <HeroSlideshow 
        title="Brand&#8203;beveiliging"
        subtitle="Zekerheid boven alles"
        content="Bedrijven zijn volgens de Arbowet verantwoordelijk voor de veiligheid van hun werknemers en bezoekers. Daarom is het van belang dat u voorzieningen treft voor uw bedrijf op het gebied van brandbeveiliging en brandveiligheid."
        slides={[brandblussers]}
        />
      <div className={classes.greyBackground}>
        <Container maxWidth="md" className={classes.section}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={4}>
              <IconBox
                icon={<FlameIcon/>}
                title="Brand&shy;schade"
                content="Schade aan het pand, productiestilstand, schadeclaims, het mislopen van opdrachten en een te lage verzekeringsuitkering om alle schade volledig te dekken. Daar moet u toch niet aan denken?"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.onderhoud}>
              <IconBox
                icon={<BuildIcon/>}
                title="Onderhoud & Controle"
                content="Blustoestellen en brandslanghaspels dienen conform de NEN Normen jaarlijks door een erkend REOB onderhoudsbedrijf blusmiddelen te worden onderhouden en gecontroleerd op veiligheid en werking. Groot in BHV & Brandbeveiliging verzorgt deze jaarlijks controle en het onderhoud van al uw blusmiddelen."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.specialistenwerk}>
              <IconBox
                icon={<PolicyIcon/>}
                title="Specialisten&shy;werk"
                content="Groot in BHV & Brandbeveiliging denkt met u mee en zorgt samen met u ervoor dat potentiële brandoorzaken weg worden genomen door uw bedrijf aan te passen."
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="md" className={classes.section}>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} sm={3}>
            <img src={brandhaspel} alt="pro-line brandslanghaspel"/>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant="h3" component="h2" gutterBottom>
              Onder andere verkrijgbaar
            </Typography>
            <Typography variant="body1" style={{marginBottom: 24}}>
              De Pro-Line brandslanghaspel is gefabriceerd en gecertificeerd volgens Kiwa EN 671-1 en CE. en wordt standaard geleverd in RAL 3000 Fire Red, compleet met montagepak (straalpijphaak, slanggeleider, montageplaat en kogelkraan).
              De haspelunit laat zich door het slide-in systeem en de duidelijke montagehandleiding eenvoudig en snel monteren. Door het slide-in systeem komen de afdichtingen op de bouwplaats niet meer bloot te liggen. Hierdoor wordt de juiste werking gegarandeerd en worden eventuele beschadigingen aan de brandslanghaspel voorkomen.
            </Typography>
            <Button color="primary" variant="contained" component={Link} to="/producten">Meer Producten</Button>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.greyBackground}>
        <Container maxWidth="md" className={classes.section} style={{textAlign: 'center'}}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2" gutterBottom>
                Neem vrijblijvend eens contact op
              </Typography>
              <Typography variant="body1" style={{marginBottom: 24}}>
                Als u meer wilt weten over onze verscheidene services en wat wij voor u kunnen betekenen als bedrijf, aarzel dan niet om contact met ons op te nemen, telefonisch of via e-mail. 
              </Typography>
              <Button color="primary" variant="contained" component={Link} to={{pathname: "/", hash: "#contact"}}>Contact</Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Brandbeveiliging;
