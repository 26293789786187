import React from 'react'
import {Link} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import FloatingContactButton from './FloatingContactButton'
import logo from '../images/logo-diepte-doorzichtige-achtergrond.png'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import MailIcon from '@material-ui/icons/Mail'
import FacebookIcon from '@material-ui/icons/Facebook'
import SchoolIcon from '@material-ui/icons/School'
import UserIcon from '@material-ui/icons/VerifiedUser'
import ContactIcon from '@material-ui/icons/PermContactCalendar'
import ShippingIcon from '@material-ui/icons/LocalShipping'



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
        backgroundColor: 'white'
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        marginLeft: 'auto'
    },
    toolbar: {
        marginBottom: 76
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        wordWrap: 'break-word',
        maxWidth: '100%',
    },
    logo: {
        maxHeight: 60,
        margin: theme.spacing(1),
        display: 'block'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

function Layout(props) {
    const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button onClick={handleDrawerToggle} component={Link} to="/brandbeveiliging" >
                    <ListItemIcon><UserIcon/></ListItemIcon>
                    <ListItemText>Brandbeveiliging</ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerToggle} component={Link} to="/cursussen" >
                    <ListItemIcon><SchoolIcon/></ListItemIcon>
                    <ListItemText>Cursussen</ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerToggle} component={Link} to="/producten" >
                    <ListItemIcon><ShippingIcon/></ListItemIcon>
                    <ListItemText>Producten</ListItemText>
                </ListItem>
                <ListItem button onClick={handleDrawerToggle} component={Link} to={{pathname: "/", hash: "#contact"}} >
                    <ListItemIcon><ContactIcon/></ListItemIcon>
                    <ListItemText>Contact</ListItemText>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} >
                <Toolbar>
                    <Link to="/">
                        <img src={logo} alt="groot in bhv en brandbeveiliging logo" className={classes.logo}/>
                    </Link>
                    <Hidden smDown implementation="js">
                        <nav style={{marginLeft: 'auto'}}>
                            <Button component={Link} to="/brandbeveiliging">Brandbeveiliging</Button>
                            <Button component={Link} to="/cursussen">Cursussen</Button>
                            <Button component={Link} to="/producten">Producten</Button>
                            <Button component={Link} to={{pathname: "/", hash: "#contact"}}>Contact</Button>
                            <a href="mailto:info@grootinbhv.nl" style={{textDecoration: 'none', marginLeft: 16}}>
                                <IconButton color="primary" variant="contained" aria-label="mail" >
                                    <MailIcon/>
                                </IconButton>
                            </a>
                            <a href="https://www.facebook.com/grootinbhvenbrandbeveiliging" style={{textDecoration: 'none', color: '#4267B2'}}>
                                <IconButton color="inherit" variant="contained" aria-label="mail" >
                                    <FacebookIcon/>
                                </IconButton>
                            </a>
                        </nav>
                    </Hidden>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
                <Hidden mdUp implementation="js">
                    <nav className={classes.drawer}>
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor="right"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </nav>
                </Hidden>
            <main className={classes.content}>
                {props.children}
                <Hidden mdUp implementation="js">
                    <FloatingContactButton/>
                </Hidden>
            </main>
        </div>
    );
}

export default Layout;