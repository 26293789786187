import React, { useState, useEffect, useRef } from 'react'
import AgendaItem from './AgendaItem'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'flex-start',
    },
    item: {
        marginBottom: theme.spacing(2)
    }
}))

const Agenda = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        name: '',
        phone: '',
        participants: '',
        groupSize: ''
    })
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URI + '/agenda')
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
            ).catch(error => console.log(error))
    }, [])

    const handleChange = (id) => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false)
    }

    const handleOpen = (index) => () => {
        setOpen(index)
    }

    const handleClose = () => {
        setOpen(false)
        resetForm()
    }

    const resetForm = () => {
        setForm({
            name: '',
            phone: '',
            participants: '',
            groupSize: '',
        })
    }

    const submitForm = (event) => {
        event.preventDefault()
        let error
        if (form.name === '') {
            error = Object.assign({}, error, {name:'Naam is een verplicht veld.'})
        }
        if (form.phone === '') {
            error = Object.assign({}, error, {phone: 'Telefoonnummer is een verplicht veld.'})
        }
        if (form.participants === '') {
            error = Object.assign({}, error, {participants: 'Aantal deelnemers is een verplicht veld.'})
        }
        if (form.participants === 'more' && form.groupSize === '') {
            error = Object.assign({}, error, {groupSize: 'Groepsgrootte is een verplicht veld.'})
        }
        if (error) {
            setErrors(error)
            return
        }
        if (form.participants === 'more') {
            formRef.current.action=`mailto:info@grootinbhv.nl?subject=Offerte Aanvraag "${data[open].title.rendered}"&body=Beste Groot in BHV %26 Brandbeveiliging,%0D%0A%0D%0AIk heb een groep van ${form.groupSize} personen die aan een "${data[open].title.rendered}" willen deelnemen. Ik zou graag willen weten of dit in een keer kan of dat de groep opgesplitst moet worden in kleinere groepen, op welke data en tijdstippen dit zou kunnen, en ik zou graag een offerte willen ontvangen voor alle deelnames.%0D%0AMijn telefoonnummer is ${form.phone}.%0D%0A%0D%0AMet vriendelijke groet,%0D%0A${form.name}`
        } else {
            formRef.current.action=`mailto:info@grootinbhv.nl?subject=Offerte Aanvraag "${data[open].title.rendered}" ${data[open].acf.datum}&body=Beste Groot in BHV %26 Brandbeveiliging,%0D%0A%0D%0AIk zou graag een offerte van jullie willen ontvangen voor de deelname van ${form.participants} personen aan de "${data[open].title.rendered}" die gegeven wordt op ${data[open].acf.datum} van ${data[open].acf.start_tijd} tot ${data[open].acf.eind_tijd}.%0D%0AMijn telefoonnummer is ${form.phone}.%0D%0A%0D%0AMet vriendelijke groet,%0D%0A${form.name}`
        }
        formRef.current.submit()
        resetForm()
        setOpen(false)
    }

    const updateForm = (event) => {
        const target = event.target;
        setForm(Object.assign({}, form, {[target.name]: target.value}))
        setErrors({})
    }

    return (
        <>
            {data.sort((a, b) => {
                const aArr = a.acf.datum.split('-').map((date) => parseInt(date));
                const aDate = new Date(aArr[2], aArr[1]-1, aArr[0]);
                const bArr = b.acf.datum.split('-').map((date) => parseInt(date));
                const bDate = new Date(bArr[2], bArr[1]-1, bArr[0]);
                if (aDate > bDate) {
                    return 1
                } else if (aDate < bDate) {
                    return -1
                } else {
                    return 0
                }
            }).map((item, index) => {
                const dateArr = item.acf.datum.split('-').map((date) => parseInt(date));
                const itemDate = new Date(dateArr[2], dateArr[1]-1, dateArr[0]);
                if (new Date() <= itemDate) {
                    return <AgendaItem
                        handleChange={handleChange(item.id)}
                        handleOpen={handleOpen(index)}
                        expanded={expanded === item.id}
                        titel={item.title.rendered}
                        beschrijving={item.acf.beschrijving}
                        vol={item.acf.vol}
                        plekken={item.acf.plaatsen_beschikbaar}
                        datum={item.acf.datum}
                        startTijd={item.acf.start_tijd}
                        eindTijd={item.acf.eind_tijd}
                        locatie={item.acf.locatie}
                        key={item.id}
                    />
                }
                return null
            })}
            <Dialog
                open={open !== false}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Offerte Aanvragen</DialogTitle>
                <form ref={formRef} method="post">
                    <DialogContent>
                        <DialogContentText>
                            Vul om succesvol een offerte aan te vragen voor de geselecteerde cursus, de onderstaande velden volledig in.
                        </DialogContentText>
                        <div className={classes.column}>
                            <TextField
                                className={classes.item}
                                error={errors.name !== undefined}
                                helperText={errors.name !== undefined ? errors.name : ''}
                                id="name"
                                type="text"
                                name="name"
                                onChange={updateForm}
                                label="Naam"
                                variant="outlined"
                                required
                            />
                            <TextField
                                className={classes.item}
                                error={errors.phone !== undefined}
                                helperText={errors.phone !== undefined ? errors.phone : ''}
                                id="phone"
                                type="text"
                                name="phone"
                                onChange={updateForm}
                                label="Telefoonnummer"
                                variant="outlined"
                                required
                            />
                            <FormControl className={classes.item} variant="outlined" required error={errors.participants !== undefined}>
                                <InputLabel id="participants-label">Aantal Deelnemers</InputLabel>
                                <Select
                                    labelId="participants-label"
                                    id="participants"
                                    name="participants"
                                    value={form.participants}
                                    onChange={updateForm}
                                    label="Aantal Deelnemers"
                                >
                                    {open &&  Array.from(Array(parseInt(data[open].acf.plaatsen_beschikbaar)), (_, i) => i + 1).map((number) => (
                                        <MenuItem value={number} key={number}>{number}</MenuItem>
                                    ))}
                                    <MenuItem value="more">Meer Deelnemers</MenuItem>
                                </Select>
                                {errors.participants !== undefined ? <FormHelperText>{errors.participants}</FormHelperText> : <FormHelperText>Vul het totaal aantal deelnemers voor deze cursus in. Klik 'Meer Deelnemers' aan om de mogelijkheden voor een grotere groep na te vragen.</FormHelperText>}
                            </FormControl>
                            {form.participants === 'more' &&
                                <TextField
                                    className={classes.item}
                                    error={errors.groupSize !== undefined}
                                    helperText={errors.groupSize !== undefined ? errors.groupSize : 'Vul de grootte van de groep deelnemers in.'}
                                    id="groupSize"
                                    type="text"
                                    name="groupSize"
                                    onChange={updateForm}
                                    label="Groepsgrootte"
                                    variant="outlined"
                                    required
                                />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                        onClick={handleClose}
                        color="primary"
                        >
                            Annuleren
                        </Button>
                        <Button 
                        onClick={submitForm}
                        color="primary"
                        >
                            Verzenden
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default Agenda