import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        color: theme.palette.grey[500],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        width: '33.33%',
        borderBottom: `2px solid ${theme.palette.grey[500]}`
    },
    icon: {
        '& svg': {
            fontSize: 32,
            margin: theme.spacing(0, 1)
        },
        '& i': {
            fontSize: 32,
            margin: theme.spacing(0, 1)
        },
    },
}))

const IconDivider = (props) => {
    const { icon } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.divider}/>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.divider}/>
        </div>
    )
}

export default IconDivider