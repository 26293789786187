import React, { useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Typography, makeStyles, Button, Container, Grid, Paper } from '@material-ui/core'
import HeroSlideshow from '../components/HeroSlideshow'
import IconDivider from '../components/IconDivider'
import reanimatie from '../images/header/reanimatie.jpg'
import defibrillator from '../images/defibrillator.png'
import bhv from '../images/bhv.png'
import vca from '../images/vca.png'
import kinder from '../images/kinder-ehbo.jpg'
import Agenda from '../components/Agenda'
import BookmarkIcon from '@material-ui/icons/BookmarkBorderOutlined'

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    overflowX: 'hidden'
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[100]
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  flexImage: {
    width: 125,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(0, 0 , 4, 0),
      width: '100%'
    }
  },
  kinderImage: {
    maxWidth: 300,
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(0, 0, 4, 0),
      width: '100%'
    }
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 580,
    marginBottom: theme.spacing(2)
  },
  cursusLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
    color: 'black',
  }
}))

const Cursussen = () => {
  const classes = useStyles();
  const location = useLocation();
  const cursusAanbod = useRef(null);
  const cursusAgenda = useRef(null);
  const aedRef = useRef(null);
  const bhvRef = useRef(null);
  const vcaRef = useRef(null);
  const kinderEhboRef = useRef(null);

  useEffect(() => {
    if (location.hash === "#cursus-aanbod") {
      window.scrollTo(0, cursusAanbod.current.offsetTop-76)
    } else if (location.hash === "#cursus-agenda") {
      window.scrollTo(0, cursusAgenda.current.offsetTop-76)
    } else if (location.hash === "#aed") {
      window.scrollTo(0, aedRef.current.offsetTop-76)
    } else if (location.hash === "#bhv") {
      window.scrollTo(0, bhvRef.current.offsetTop-76)
    } else if (location.hash === "#vca") {
      window.scrollTo(0, vcaRef.current.offsetTop-76)
    } else if (location.hash === "#kinder-ehbo") {
      window.scrollTo(0, kinderEhboRef.current.offsetTop-76)
    } else {
      window.scrollTo(0, 0)
    }
  })

  return (
    <>
      <HeroSlideshow 
        title="Cursussen & Opleidingen"
        subtitle="Leer handelen bij een ongeluk"
        content="Wij verzorgen BHV (Basis & Herhaling), AED & Reanimatie, VCA (Basis & Vol) en Kinder-EHBO cursussen en opleidingen. Vind meer informatie onder het kopje 'cursus aanbod' of ga direct door naar onze agenda."
        slides={[reanimatie]}
      >
        <div>
          <Button color="primary" variant="contained" style={{marginRight: 16}} component={Link} to={{pathname: "/cursussen", hash: "#cursus-aanbod"}}>Cursus Aanbod</Button>
          <Button color="primary" variant="contained" component={Link} to={{pathname: "/cursussen", hash: "#cursus-agenda"}}>Agenda</Button>
        </div>
      </HeroSlideshow>
      <Container ref={cursusAanbod} maxWidth="md" className={classes.section}>
        <div style={{marginBottom: 80}}>
          <Typography variant="h3" component="h2" align="center" style={{marginBottom: 32}}>
            Cursus Aanbod
          </Typography>
          <IconDivider icon={<BookmarkIcon/>}/>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.cursusLink} component={Link} to={{pathName: '/cursussen', hash: '#aed'}}>1. AED & Reanimatie</Typography>
              <Typography className={classes.cursusLink} component={Link} to={{pathName: '/cursussen', hash: '#bhv'}}><br/>2. Bedrijfshulpverlening (BHV) - Basis & Herhaling</Typography>
              <Typography className={classes.cursusLink} component={Link} to={{pathName: '/cursussen', hash: '#vca'}}><br/>3. Veiligheid, gezondheid, milieu Checklist Aannemers (VCA) - Basis & Vol</Typography>
              <Typography className={classes.cursusLink} component={Link} to={{pathName: '/cursussen', hash: '#kinder-ehbo'}}><br/>4. Kinder-EHBO</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} ref={aedRef}>
            <Typography variant="h4" component="h3">
              AED & Reanimatie
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.flexContainer}>
              <img className={classes.flexImage} src={defibrillator} alt="defibrillator"/>
              <Typography variant="body1">
                <b>Wat doet u als een medemens een hartstilstand krijgt?</b>
                <br/>Voor die situatie is er nu de Automatische Externe Defibrillator (AED). De kans op overleving met een AED na een hartcrisis stijgt enorm. De AED is eenvoudig te bedienen en werkt geheel automatisch: ook ú kunt een leven redden met een druk op de knop. Uiteraard moet u wel kunnen reanimeren om een AED te kunnen bedienen en daarbij is een dagdeel training noodzakelijk.
              </Typography>
            </div>
            <Typography variant="body1">
              <br/><b>Wat is een AED?</b>
              <br/>Een AED is een apparaat waarmee men een elektrische schok aan het hart kan toedienen, wanneer er sprake is van levensbedreigende hartritmestoornissen. De AED analyseert het hartritme van het slachtoffer en bepaalt automatisch of het noodzakelijk is een stroomstoot toe te dienen. De AED leidt de gebruiker op een veilige wijze door de reanimatie totdat professionele hulpverleners het kunnen overnemen.
              <br/>
              <br/><b>Waarom is de aanwezigheid van een AED noodzakelijk?</b>
              <br/>Door bij een hartstilstand vroegtijdig (binnen 3 tot 5 minuten) het hart te defibrilleren vergroot men de kans op overleven tot 70%. Iedere minuut dat er gewacht wordt met defibrillatie, neemt de overlevingskans met 10 tot 12% af. Met een gemiddelde aanrijtijd van een ambulance van 12 minuten is de gemiddelde overlevingskans 10%. Steeds meer bedrijven gaan dan ook over tot de aanschaf van een AED om de veiligheid van hun personeel en bezoekers te vergroten.
              <br/>
              <br/><b>AED en toebehoren</b>
              <br/>Wij adviseren onze klanten de Defitech Lifeline AED vanwege de gunstige prijs-kwaliteit verhouding en de gebruiksvriendelijkheid. Het gebruiksgemak is stapsgewijs. Met gesproken woord wordt u door de reanimatiestappen geleid. De AED is ontwikkeld om te voldoen aan de hoge eisen van professionele hulpverleners. Eenvoudig genoeg voor medische leken. Het redden van mensenlevens ligt nu binnen ieders handbereik.
              <br/>
              <br/>De Defibtech Lifeline AED valt direct op door zijn slimme en gebruiksvriendelijke ontwerp. Eenvoud in bedieningsgemak komt namelijk de snelheid van levensreddend handelen ten goede. Elke seconde telt!
              <br/>
              <br/><b>De voordelen:</b>
            </Typography>
            <Typography variant="body1" component="ol">
              <li>Eenvoudige en snelle bediening</li>
              <li>Heldere stembegeleiding</li>
              <li>Ergonomisch en robuust ontwerp</li>
              <li>Meest recente schokprotocol</li>
              <li>Zéér lange stand-by tijd van 7 jaar</li>
              <li>Kosteloze software updates</li>
              <li>5 jaar garantie</li>
            </Typography>
          </Grid>
          <Grid item xs={12} ref={bhvRef}>
            <Typography variant="h4" component="h3">
              Bedrijfshulpverlening (BHV) - Basis & Herhaling
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.flexContainer}>
              <img className={classes.flexImage} src={bhv} alt="defibrillator"/>
              <Typography variant="body1">
                <b>Een ongeluk zit in een klein hoekje</b>
                <br/>Een collega of klant die plotseling gewond raakt bij een bedrijfsongeval of er is een brandmelding. Gelukkig heeft u bedrijfshulpverleners binnen uw bedrijf die precies weten wat er moet gebeuren. Up-to-date en speciaal opgeleid en kunnen direct de juiste maatregelen nemen.
              </Typography>
            </div>
            <Typography variant="body1">
              <br/><b>Training op maat</b>
              <br/>Voor u op maat gemaakt kan ook bij u op locatie BHV-cursus worden gegeven. Uiteraard is het ook mogelijk op een ander geplande locatie de cursus te geven op maat gemaakt op uw branche en door Henk en/of Aukje de Groot persoonlijk gegeven.
              <br/>
              <br/><b>De ARBO-wet</b>
              <br/>Ieder bedrijf is verplicht om één of meerdere medewerkers op te leiden tot bedrijfshulpverlener (BHV-er). De ARBO-wet stelt dat er één of meerdere bedrijfshulpverlener(s) aanwezig dienen te zijn. Denkt u daarbij aan afwezigheid (ziekte, vakantie) van de BHV-er.
              <br/>
              <br/><b>Letsel en schade beperken</b>
              <br/>De risico-inventarisatie en -evaluatie (RI&E) is het uitgangspunt om te bepalen welke deskundige bijstand op het gebied van BHV nodig is. Van levensbelang is het om over een complete bedrijfsverbanddoos en/of AED te beschikken zodat de eerste noodzakelijke maatregelen genomen worden. Bedrijfshulpverlening (BHV) is bedoeld om letsel en schade van medewerkers, bezoekers en klanten zoveel mogelijk te voorkomen en te beperken.
              <br/>
              <br/>De voornaamste taken van bedrijfshulpverleners zijn:
            </Typography>
            <Typography variant="body1" component="ul">
              <li>het verlenen van eerste hulp bij ongevallen</li>
              <li>het beperken en het bestrijden van een beginnende brand</li>
              <li>het in noodsituaties alarmeren en evacueren van alle werknemers en andere mensen in het gebouw</li>
            </Typography>
            <Typography variant="body1">
              <br/>De bedrijfshulpverleners handelen na ongeval of brand, tot het moment waarop de professionele hulpverlening zoals brandweer en andere hulpverlenersorganisatie deze taken over kunnen nemen.
            </Typography>
          </Grid>
          <Grid item xs={12} ref={vcaRef}>
            <Typography variant="h4" component="h3">
              Veiligheid, gezondheid, milieu Checklist Aannemers (VCA) - Basis & Vol
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.flexContainer}>
              <img className={classes.flexImage} src={vca} alt="defibrillator"/>
              <Typography variant="body1">
                <b>Veilig en verantwoord werken</b>
                <br/>VCA-Organisaties hebben er alle belang bij dat op hun bedrijfsterreinen en locaties veilig en verantwoord wordt gewerkt. Organisaties kunnen eisen dat aannemers of leveranciers het VCA (Veiligheid, gezondheid en milieu Checklist Aannemers) certificaat overleggen, voordat zij risicovolle werkzaamheden mogen uitvoeren op het bedrijfsterrein. 
              </Typography>
            </div>
            <Typography variant="body1">
              <br/><b>De ARBO-wet</b>
              <br/>De Arbo-wet stelt dat werkgevers verplicht zijn te zorgen voor de veiligheid van derden, die zich op het bedrijf bevinden. Denk bijvoorbeeld aan mensen werkzaam in de bouw, de metaalindustrie en in de horeca. Veel ongevallen zijn vaak het gevolg van hoge werkdruk, onoplettendheid en het niet voldoende op de hoogte zijn van de veiligheidsvoorschriften.
              <br/>
              <br/><b>Doel</b>
              <br/>Deze opleiding geeft inzicht in de werkhouding, bevordert veilig gedrag en versterkt het veiligheidsbewustzijn
              <br/>
              <br/><b>Doelgroep</b>
              <br/>Deze opleiding is bedoeld voor uitvoerende medewerkers, operationeel leidinggevenden en leidinggevenden. Voor leidinggevenden geldt als aanvullende eis het certificaat Veiligheid voor Operationeel Leidinggevenden.
              <br/>
              <br/><b>Programma VCA</b>
              <br/>
            </Typography>
            <Typography variant="body1" component="ol">
              <li>Risico’s en calamiteiten</li>
              <li>Wet- en regelgeving</li>
              <li>Branden en explosies</li>
              <li>Gevaarlijke stoffen</li>
              <li>Machines en gereedschappen</li>
              <li>Tillen en hijsen</li>
              <li>Persoonlijke beschermingsmiddelen</li>
              <li>Kleine blusmiddelen</li>
            </Typography>
            <Typography variant="body1">
              <br/>Er is ook de mogelijkheid, in overleg, om de opleiding op uw bedrijf te verzorgen. De trainingen vinden plaats op het bedrijf en de locatie door u aangegeven, tenzij niet aan de eisen en voorwaarden kan worden voldaan. Bij open inschrijving wordt er een goed bereikbare locatie gekozen en beschikbare data gepland om training te geven. Compleet verzorgt en desgewenst voorzien van een lunch.
              <br/>
              <br/><b>Alle Cursussen VCA zijn op aanvraag. Neem contact op voor de mogelijkheden!</b>
            </Typography>
          </Grid>
          <Grid item xs={12} ref={kinderEhboRef}>
            <Typography variant="h4" component="h3">
              Kinder-EHBO
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={kinder} alt="kinder-EHBO teddy beer" className={classes.kinderImage}/>
            <Typography variant="body1">
              <br/><b>Praktijktraining</b>
            </Typography>
            <Typography variant="body1" component="ul">
              <li>Veiligheid in en om het huis</li>
              <li>Controleren van het bewustzijn en ademhaling bij een baby en kind</li>
              <li>Vrijmaken van de ademweg en de Heimlich methode</li>
              <li>Reanimatie zuigeling en kind</li>
              <li>Ernstige uitwendige bloedingen stelpen (wonddrukverband) en shock voorkomen</li>
              <li>Aanleggen van een verband, drukverband en mitella</li>
              <li>Uitwendige wonden behandelen (schaaf, snij en bijtwonden)</li>
              <li>Brandwonden</li>
              <li>Ontwrichting / botbreuken / kneuzingen</li>
              <li>Behandelen bloedneus, vuiltje oog verwijderen, teken en splinters verwijderen</li>
              <li>Oog afplakken bij oogletsel</li>
              <li>Kinderziektes</li>
              <li>Verdere vragen/wensen</li>
            </Typography>
            <Typography variant="body1">
              <br/><b>Voor wie?</b>
              <br/>Voor ouders, gastouders, opa’s en oma’s
              <br/>
              <br/><b>Duur</b>
              <br/>Twee avonden of een dag
              <br/>
              <br/><b>Cursus data</b>
              <br/>In overleg bij voldoende deelname op uw werkplek of bij u thuis. Of op aangegeven data in de agenda (onder voorbehoud van voldoende deelname)
              <br/>
              <br/><b>Certificaat</b>
              <br/>Na afloop van de training ontvangt u een certificaatof diploma van het Oranjekruis. U voldoet bij competent verklaring aan de eisen van het Convenant Kwaliteit Kinderopvang op gebied van EHBO voor kinderen volgens de eindtermen van het Oranje Kruis.
              <br/>
              <br/><b>Het Oranje Kruis</b>
              <br/>Het Oranje Kruis is de organisatie op het gebied van Eerste Hulp (EHBO) die de leerstof samenstelt, de kwaliteit bewaakt van de cursussen, examens afneemt, het diploma Eerste Hulp uitreikt en de geldigheid daarvan bewaakt. Het Oranje Kruis verzorgt zelf geen cursussen Eerste Hulp. Dit gebeurt door ruim 1.500 vrijwilligersverenigingen en 250 commerciële opleidingsinstituten.
              <br/>
              <br/><b>Deelname</b>
              <br/>Heeft u belangstelling voor een cursus of heeft u nog vragen dan kunt u vrijblijvend contact met ons opnemen.
              <br/>
              <br/><b>Examen</b>
              <br/>De cursus wordt afgesloten met een examen. U ontvangt bij competent verklaring u persoonlijke Kinder-EHBO certificering.
              <br/>
              <br/><b>Locatie</b>
              <br/>De cursus wordt gegeven op locatie in Burgum of op locatie bij u of in de buurt.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container ref={cursusAgenda} maxWidth="md" className={classes.section}>
        <div style={{marginBottom: 80}}>
          <Typography variant="h3" component="h2" align="center" style={{marginBottom: 32}}>
            Agenda
          </Typography>
          <IconDivider icon={<BookmarkIcon/>}/>
        </div>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Agenda/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Cursussen;
 