import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    icon: {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        '& i': {
            fontSize: 70
        },
        '& svg': {
            fontSize: 70
        },
    },
    content: {
        marginBottom: theme.spacing(3)
    }
}))

const IconBox = (props) => {
    const { icon, title, content, children } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.icon}>{icon}</div>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" align="center" className={classes.content}>
                {content}
            </Typography>
            {children}
        </div>
    )
}

export default IconBox