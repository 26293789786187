import React, { useState } from 'react'
import Fab from '@material-ui/core/Fab'
import Zoom from '@material-ui/core/Zoom'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import FacebookIcon from '@material-ui/icons/Facebook'
import ChatIcon from '@material-ui/icons/Chat'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import CloseIcon from  '@material-ui/icons/Close'
import { makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(2)
    },
    close: {
        backgroundColor: theme.palette.grey[500],
        color: 'white'
    },
    menu: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const FloatingContactButton = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const handleOpen = () => {
        setOpen(!open);
    }

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };
    
    return (
        
            <div className={classes.menu}>
                {!open &&
                <Zoom 
                    in={!open} 
                    timeout={transitionDuration} 
                    style={{
                        transitionDelay: !open ? transitionDuration.exit : 0,
                    }}
                    unmountOnExit
                >
                    <Fab onClick={handleOpen} color="primary" aria-label="open contact menu">
                        <ChatIcon/>
                    </Fab>
                </Zoom>}
                {open && <>
                    <Zoom 
                        in={open} 
                        timeout={transitionDuration} 
                        style={{
                            transitionDelay: open ? transitionDuration.exit + 400: 0,
                        }}
                        unmountOnExit
                    >
                        <a href="tel:+31627081802" style={{textDecoration: 'none'}}>
                            <Fab color="primary" size="small" aria-label="call" className={classes.button}>
                                <PhoneIcon/>
                            </Fab>
                        </a>
                    </Zoom>
                    <Zoom 
                        in={open} 
                        timeout={transitionDuration} 
                        style={{
                            transitionDelay: open ? transitionDuration.exit + 300 : 0,
                        }}
                        unmountOnExit
                    >
                        <a href="mailto:info@grootinbhv.nl" style={{textDecoration: 'none'}}>
                            <Fab color="inherit" size="small" style={{backgroundColor: '#0072c6', color: 'white'}} aria-label="mail" className={classes.button}>
                                <MailIcon/>
                            </Fab>
                        </a>
                    </Zoom>
                    <Zoom 
                        in={open} 
                        timeout={transitionDuration} 
                        style={{
                            transitionDelay: open ? transitionDuration.exit + 200 : 0,
                        }}
                        unmountOnExit
                    >
                        <a href="https://wa.me/31627081802" style={{textDecoration: 'none'}}>
                            <Fab color="inherit" size="small" style={{backgroundColor: '#25D366', color: 'white'}} aria-label="whatsapp" className={classes.button}>
                                <WhatsAppIcon/>
                            </Fab>
                        </a>
                    </Zoom>
                    <Zoom 
                        in={open} 
                        timeout={transitionDuration} 
                        style={{
                            transitionDelay: open ? transitionDuration.exit + 200 : 0,
                        }}
                        unmountOnExit
                    >
                        <a href="https://www.facebook.com/grootinbhvenbrandbeveiliging" style={{textDecoration: 'none'}}>
                            <Fab color="inherit" size="small" style={{backgroundColor: '#4267B2', color: 'white'}} aria-label="facebook" className={classes.button}>
                                <FacebookIcon/>
                            </Fab>
                        </a>
                    </Zoom>
                    <Zoom 
                        in={open} 
                        timeout={transitionDuration} 
                        style={{
                            transitionDelay: open ? transitionDuration.exit : 0,
                        }}
                        unmountOnExit
                    >
                        <Fab onClick={handleOpen} color="inherit" className={classes.close} aria-label="close contact menu">
                            <CloseIcon/>
                        </Fab>
                    </Zoom>
                    
                </>}
            </div>
    )
    
};

export default FloatingContactButton;