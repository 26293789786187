import React, { useState } from 'react'
import { makeStyles, Backdrop, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import NextIcon from '@material-ui/icons/NavigateNext'
import PreviousIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 1px'
    },
    imageContainer: {
        position: 'relative',
        margin: 2,
        [theme.breakpoints.only('xs')]: {
            width: `calc(33.33vw - 6px)`,
            height: `calc(33.33vw - 6px)`,
        },
        [theme.breakpoints.up('sm')]: {
            width: `calc(25vw - 6px)`,
            height: `calc(25vw - 6px)`,
        },
        [theme.breakpoints.up('lg')]: {
            width: `calc(16.5vw - 6px)`,
            height: `calc(16.5vw - 6px)`
        },
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        opacity: 0,
        transition: 'opacity .5s ease',
        backgroundColor: 'white',
        zIndex: 1,
        '&:hover': {
            opacity: 0.3
        }
    }, 
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
    },
    close: {
        position: 'absolute',
        top: 5,
        right: 5
    },
    next: {
        position: 'absolute',
        top: 'calc(50vh - 25px)',
        right: 0
    },
    prev: {
        position: 'absolute',
        top: 'calc(50vh - 25px)',
        left: 0,
    },
    activeImage: {
        maxWidth: '100vw',
        maxHeight: '100vh'
    }
}))

const Gallery = (props) => {
    const { images } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    
    const handleOpen = (index) => {
        setIndex(index);
        setOpen(true);
    }

    const handleClose = (e) => {
        const id = e.target.id;
        if(id === 'backdrop' || id === 'close' || id === 'close-icon') {
            setOpen(false)
        }
    }

    const handleNext = () => {
        if(index === images.length-1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }

    const handlePrevious = () => {
        if(index === 0) {
            setIndex(images.length-1)
        } else {
            setIndex(index - 1)
        }
    }
    
    return (
        <div className={classes.container}>
            {images.map((image, index) => (
                <div onClick={() => handleOpen(index)} className={classes.imageContainer}>
                    <img key={index} src={image.src} alt={image.alt} className={classes.img}/>
                    <div className={classes.overlay}/>
                </div> 
            ))} 
            <Backdrop id="backdrop" className={classes.backdrop} open={open} onClick={handleClose} transitionDuration={500}>
                <img className={classes.activeImage} src={images[index].src} alt={images[index].alt}/>
                <IconButton id="close" onClick={handleClose} className={classes.close} aria-label="close" color="inherit">
                    <CloseIcon id="close-icon" style={{fontSize: 28, color: 'white'}}/>
                </IconButton>
                <IconButton onClick={handleNext} className={classes.next} aria-label="close" color="inherit">
                    <NextIcon style={{fontSize: 38, color: 'white'}}/>
                </IconButton>
                <IconButton onClick={handlePrevious} className={classes.prev} aria-label="close" color="inherit">
                    <PreviousIcon style={{fontSize: 38, color: 'white'}}/>
                </IconButton>
            </Backdrop>
        </div>
    )
}

export default Gallery