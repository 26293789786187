import React, { useState } from 'react'
import { makeStyles, Typography, Container, IconButton } from '@material-ui/core'
import useInterval from '../hooks/useInterval'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 0
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5);',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1
    },
    slide: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        minHeight: '100%',
        zIndex: -2,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'opacity 1s linear',
        opacity: 0,
    },
    subtitle: {
        [theme.breakpoints.only('xs')]: {
            textAlign: 'left',
        },
        marginBottom: theme.spacing(6)
    },
    title: {
        [theme.breakpoints.only('xs')]: {
            textAlign: 'left',
        },
        marginBottom: theme.spacing(4)
    },
    content: {
        [theme.breakpoints.only('xs')]: {
            textAlign: 'left',
        },
        marginBottom: theme.spacing(4),
        fontSize: 14
    },
    downButton: {
        color: 'white',
    },
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            alignItems: 'flex-start',
        },
    }
}))

const HeroSlideshow = (props) => {
    const { children, title, subtitle, content, slides, interval = 5000 } = props;
    const classes = useStyles();
    const [current, setCurrent] = useState(0);

    useInterval(() => {
        if(slides.length-1 === current) {
            setCurrent(0)
        } else {
            setCurrent(current + 1)
        }
    }, interval)

    const handleDown = () => {
        let y = window.scrollY; //y-axis pixel displacement
        let delay = 1; //delay in milliseconds
        let inc = 5 //if you want to increase speed simply increase increment interval
        let vh = window.innerHeight - 76;

        const scroll = setInterval(() => {
            window.scroll(0, y);
            y = y + inc;
            if (y + inc >= vh) {
                window.scroll(0, vh + 1)
                clearInterval(scroll)
            }
        }, delay);
    }

    return (
        <div className={classes.root}>
            {slides.map((slide, index) => {
                if (index === current) {
                    return <div key={index} className={classes.slide} style={{backgroundImage: `url(${slide})`, opacity: 1}}/>
                }
                return <div key={index} className={classes.slide} style={{backgroundImage: `url(${slide})`}}/>
            })}
            <div className={classes.overlay}/>
            <Container maxWidth="md" className={classes.container}>
                <Typography variant="overline" align="center" className={classes.subtitle}>
                    {subtitle}
                </Typography>
                <Typography variant="h3" component="h1" className={classes.title} align="center">
                    {title}
                </Typography>
                <Typography variant="caption" align="center" className={classes.content}>
                    {content}
                </Typography>
                {children}
            </Container>
            <IconButton onClick={handleDown} aria-label="down" className={classes.downButton}>
                <ExpandMoreIcon style={{fontSize: 40}}/>
            </IconButton>
        </div>
    )
}

export default HeroSlideshow