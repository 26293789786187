import React, { useEffect, useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import Product from '../components/Product'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Container, Paper, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField} from '@material-ui/core'
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import AlarmIcon from '@material-ui/icons/Alarm'
import HealingIcon from '@material-ui/icons/Healing'
import BoltIcon from '@material-ui/icons/OfflineBolt'
import FireHoseIcon from '../images/fire_hose.svg'



const useStyles = makeStyles((theme) => ({
    toolbar: {
        marginBottom: 76
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'stretch',
    },
    input: {
        marginBottom: theme.spacing(2)
    },
    section: {
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(15),
        overflowX: 'hidden'
    },
    relative: {
        position: 'relative',
        marginBottom: theme.spacing(1)
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - 2*${theme.spacing(4)}px)`,
            margin: theme.spacing(0, 4),
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowX: 'hidden',
    },
    next: {
        position: 'absolute',
        top: 'calc(50% - 24px)',
        right: 0,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    prev: {
        position: 'absolute',
        top: 'calc(50% - 24px)',
        left: 0,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(0.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    icon: {
        textAlign: 'center',
        height: 36,
        marginBottom: theme.spacing(1),
        '& i': {
            fontSize: 36
        },
        '& svg': {
            fontSize: 36
        },
    },
    item: {
        flex: '1 0 auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(45% - 8px)',
        },
        [theme.breakpoints.only('sm')]: {
            width: 'calc(19% - 8px)',
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(16% - 8px)',
        },
        height: 125,
        borderColor: theme.palette.grey[300],
        color: theme.palette.grey[600],
    },
    active: {
        flex: '2 0 auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(55% - 8px)',
        },
        [theme.breakpoints.only('sm')]: {
            width: 'calc(24% - 8px)',
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(20% - 8px)',
        },
        height: 150,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main
    }
}));

const categoriesList = () => ([
    {
        icon: <i className="material-icons">fire_extinguisher</i>,
        name: 'Brand\xADblussers & Blus\xADdekens',
        category: 'brandblussers'
    },
    {
        icon: <img src={FireHoseIcon} alt="brandslanghaspel" style={{height: 36}}/>,
        name: 'Brand\xADslang\xADhaspels',
        category: 'brandslanghaspels'
    },
    {
        icon: <AlarmIcon/>,
        name: 'Evenementen Verhuur',
        category: 'evenementen'
    },
    {
        icon: <HealingIcon/>,
        name: 'Verband\xADtrommels',
        category: 'verbandtrommels'
    },
    {
        icon: <BoltIcon/>,
        name: 'AED\'s',
        category: 'aed'
    },
    {
        icon: <i className="material-icons">masks</i>,
        name: 'Desinfectie\xADmiddelen & Mond\xADkapjes',
        category: 'desinfectiemiddelen'
    }
])

function Producten() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [active, setActive] = useState(0);
    const [categories, setCategories] = useState(categoriesList)
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        name: '',
        phone: '',
        number: '',
    })
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URI + '/producten')
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
            ).catch(error => console.log(error))
    }, [])

    useEffect(() => {
        setFilteredData(data.filter((product) => {
            return product.acf.categorie === categories[active].category
        }))
    }, [data, active, categories])

    const handleActive = (index) => () => {
        setActive(index)
    }

    const handleNext = () => {
        let copy = [...categories]
        let first = copy.shift()
        copy.push(first)
        setCategories(copy)
        if (active === 0) {
            setActive(categories.length-1)
        } else {
            setActive(active - 1)
        }
    }

    const handlePrevious = () => {
        let copy = [...categories]
        let first = copy.pop()
        copy.unshift(first)
        setCategories(copy)
        if (active === categories.length-1) {
            setActive(0)
        } else {
            setActive(active + 1)
        }
    }

    const handleOpen = (index) => () => {
        setOpen(index)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const resetForm = () => {
        setForm({
            name: '',
            phone: '',
            number: '',
        })
    }

    const submitForm = (event) => {
        event.preventDefault()
        let error
        if (form.name === '') {
            error = Object.assign({}, error, {name:'Naam is een verplicht veld.'})
        }
        if (form.phone === '') {
            error = Object.assign({}, error, {phone: 'Telefoonnummer is een verplicht veld.'})
        }
        if (form.number === '') {
            error = Object.assign({}, error, {number: 'Aantal Stuks is een verplicht veld.'})
        } else if (!/^[1-9][0-9]*$/.test(form.number)) {
            error = Object.assign({}, error, {number: 'Aantal Stuks moet een getal zijn dat groter dan of gelijk is aan 1.'})
        }
        if (error) {
            setErrors(error)
            return
        }
        formRef.current.action=`mailto:info@grootinbhv.nl?subject=Offerte Aanvraag ${form.number}x "${filteredData[open].title.rendered}"&body=Beste Groot in BHV %26 Brandbeveiliging,%0D%0A%0D%0AIk zou graag een offerte van jullie willen ontvangen voor ${form.number} maal de "${filteredData[open].title.rendered}" die op jullie website vermeld staat.%0D%0AMijn telefoonnummer is ${form.phone}.%0D%0A%0D%0AMet vriendelijke groet,%0D%0A${form.name}`
        formRef.current.submit()
        resetForm()
        setOpen(false)
    }

    const updateForm = (event) => {
        const target = event.target;
        setForm(Object.assign({}, form, {[target.name]: target.value}))
        setErrors({})
    }

    return (
        <>
            <div className={classes.toolbar}/>
            <Container maxWidth="md" className={classes.section}>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1">
                            Product Categorieën
                        </Typography>
                        <Typography variant="caption">
                            Selecteer een categorie om de producten te filteren
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.relative}>
                        <div className={classes.container}>
                            {categories.map((categorie, index) => {
                                return (
                                    <Paper key={categorie.name} onClick={handleActive(index)} className={`${classes.paper} ${index === active ? classes.active : classes.item}`} variant="outlined">
                                        <div className={classes.icon}>
                                            {categorie.icon}
                                        </div>
                                        <Typography variant="body2" align="center">
                                            {categorie.name}
                                        </Typography>
                                    </Paper>
                                )
                            })}
                            <IconButton onClick={handleNext} className={classes.next}><NextIcon fontSize="inherit"/></IconButton>
                            <IconButton onClick={handlePrevious} className={classes.prev}><PrevIcon fontSize="inherit"/></IconButton>
                        </div>
                    </Grid>
                    <Grid container item xs={12} justify="flex-start">
                    {
                        filteredData.length === 0 
                        ?   <Typography variant="h5" component="h2">
                                Geen Producten Gevonden
                            </Typography>
                        :   filteredData.map((product, index) => (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Product
                                        key={product.id}
                                        title={product.title.rendered}
                                        image={product.acf.afbeelding}
                                        content={product.acf.beschrijving}
                                        onClick={handleOpen(index)}
                                    />
                                </Grid>
                            ))
                    }
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open !== false}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Offerte Aanvragen</DialogTitle>
                <form ref={formRef} method="post">
                    <DialogContent>
                        <DialogContentText>
                            Vul om succesvol een offerte aan te vragen voor de geselecteerde cursus, de onderstaande velden volledig in.
                        </DialogContentText>
                        <div className={classes.column}>
                            <TextField
                                className={classes.input}
                                error={errors.name !== undefined}
                                helperText={errors.name !== undefined ? errors.name : ''}
                                id="name"
                                type="text"
                                name="name"
                                onChange={updateForm}
                                label="Naam"
                                variant="outlined"
                                required
                            />
                            <TextField
                                className={classes.input}
                                error={errors.phone !== undefined}
                                helperText={errors.phone !== undefined ? errors.phone : ''}
                                id="phone"
                                type="text"
                                name="phone"
                                onChange={updateForm}
                                label="Telefoonnummer"
                                variant="outlined"
                                required
                            />
                            <TextField
                                className={classes.input}
                                error={errors.number !== undefined}
                                helperText={errors.number !== undefined ? errors.number : ''}
                                id="number"
                                type="text"
                                name="number"

                                onChange={updateForm}
                                label="Aantal Stuks"
                                variant="outlined"
                                required
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                        onClick={handleClose}
                        color="primary"
                        >
                            Annuleren
                        </Button>
                        <Button 
                        onClick={submitForm}
                        color="primary"
                        >
                            Verzenden
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default Producten;
