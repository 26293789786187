import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { Button, CardActions } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        
    },    
    media: {
        height: 200
    },
    content: {
        
    },
}));

const Product = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={props.image}
                title={props.title}
            />
            <CardContent> 
                <Typography gutterBottom={props.content !== ''} variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.content}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={props.onClick} color="primary">Offerte Aanvragen</Button>
            </CardActions>
        </Card>
    );
}


export default Product;